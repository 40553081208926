.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.CCToolbar>.MuiToolbar-root.MuiToolbar-regular {
  display: flex !important;
  align-items: center !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.CCToolbar>.MuiPaper-root>div:first-child {
  display: none !important;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#loader {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(155, 155, 155, 0.7);
}

.clickable {
  cursor: pointer;
}